export default {
    primaryResident: 'Kandidaat',
    requester: 'Kandidaat-huurder',

    aResidentOwnsProperty: 'Ben jij, of is iemand uit jouw gezin, in het bezit van een eigendom?',
    aResidentOwnsProperty_leuven:
        'Ben jij, of is iemand uit jouw gezin in het bezit van een eigendom of heeft vruchtgebruik?',

    aResidentOwnsPropertyTitle: 'Eigendom',
    aResidentOwnsPropertyTitleHelpText: 'Je mag geen eigendom (onroerend goed) hebben voor deze aanvraag.',

    hasResidentialHistoryTitle: 'Woonhistoriek',
    hasResidentialHistory:
        'Woonde jij, je partner of een ander meerderjarig gezinslid 3 jaar in {{city}} of districten tijdens de laatste 10 jaar?',
    hasResidentialHistoryHelpText:
        'We vragen hier naar je officiële adres. Geen adressen van studentenkoten of tijdelijke logementen.',
    residentialHistoryDocumentHelpText:
        'Laad een attest op waarin je je woonhistoriek bewijst. Je kan een attest opvragen ',
    residentialHistoryDocumentLink: 'via volgende link',

    hasDisabilityDocument: 'Heb je een attest van invaliditeit?',
    hasDisabilityDocumentTitle: 'Invaliditeit',
    hasDisabilityDocumentUpload: 'Gelieve een attest van invaliditeit op te laden.',
    hasDisabilityDocumentHelpText: 'Je kan dit attest opvragen via',
    hasDisabilityDocumentLink: 'My Handicap',

    communicationPreference: ' Hoe kunnen we je contacteren over je aanvraag?',
    contactInfo: 'Contactgegevens',
    contactPreference: 'Hoe wil je gecontacteerd worden?',
    EMAIL: 'Via e-mail',
    LETTER: 'Via post',
};
