import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, boolean, object, string } from 'yup';
import { TFunction } from 'i18next';
import { validateFutureDate } from './date.validator';

const financialDataValidator = (t: TFunction) => {
    const { budgetRentingDocument, rentSubsidy } = window.carity.environment;

    return object().shape({
        id: string().optional(),
        taxYear: string().when(['isJointTaxReturn'], {
            is: (isJointTaxReturn: boolean) => !isJointTaxReturn,
            then: (schema) => schema.required(t('requiredQuestion')),
            otherwise: (schema) => schema.strip(),
        }),
        isJointTaxReturn: boolean().nullable(),
        jointTaxableIncome: string().when('taxYear', {
            is: 'none',
            then: (schema) => schema.strip(),
            otherwise: (schema) =>
                schema.test('required', t('requiredQuestion'), (value) => value !== '' && value !== null),
        }),
        hasIndividualTaxableIncome: boolean()
            .nullable()
            .when('taxYear', {
                is: 'none',
                then: (schema) => schema.strip(),
                otherwise: (schema) => schema.required(t('requiredQuestion')),
            }),
        individualTaxableIncome: string().when('hasIndividualTaxableIncome', {
            is: true,
            then: (schema) => schema.required(t('requiredQuestion')),
            otherwise: (schema) => schema.strip(),
        }),
        hasMonthlyTaxableIncome: boolean()
            .nullable()
            .when('taxYear', {
                is: 'none',
                then: (schema) => schema.required(t('requiredQuestion')),
                otherwise: (schema) => schema.strip(),
            }),
        monthlyTaxableIncome: string().when('hasMonthlyTaxableIncome', {
            is: true,
            then: (schema) => schema.required(t('requiredQuestion')),
            otherwise: (schema) => schema.strip(),
        }),
        hasLivingWage: boolean()
            .nullable()
            .when('taxYear', {
                is: 'none',
                then: (schema) => schema.strip(),
                otherwise: (schema) => schema.required(t('requiredQuestion')),
            }),
        livingWage: string().when('hasLivingWage', {
            is: true,
            then: (schema) =>
                schema.test('moneyFieldGreaterThanZero', t('moneyFieldGreaterThanZero'), (value) => Number(value) > 0),
            otherwise: (schema) => schema.strip(),
        }),
        hasDisabilityIncome: boolean()
            .nullable()
            .test('required', t('requiredQuestion'), (value) => value !== null),
        disabilityIncome: string().when('hasDisabilityIncome', {
            is: true,
            then: (schema) =>
                schema.test('moneyFieldGreaterThanZero', t('moneyFieldGreaterThanZero'), (value) => Number(value) > 0),
            otherwise: (schema) => schema.strip(),
        }),
        hasForeignIncome: boolean()
            .nullable()
            .test('required', t('requiredQuestion'), (value) => value !== null),
        foreignIncome: string().when('hasForeignIncome', {
            is: true,
            then: (schema) =>
                schema.test('moneyFieldGreaterThanZero', t('moneyFieldGreaterThanZero'), (value) => Number(value) > 0),
            otherwise: (schema) => schema.strip(),
        }),
        hasBudgetRentingDocument: boolean()
            .nullable()
            .test(
                'required',
                t('requiredQuestion'),
                (value, context) =>
                    !budgetRentingDocument ||
                    (context.from && context.from[1] && !context.from[1]?.value.isPrimaryResident) ||
                    value !== null,
            ),
        expirationDateBudgetRentingDocument: validateFutureDate(t, 'hasBudgetRentingDocument', budgetRentingDocument),
        hasRentSubsidy: boolean()
            .nullable()
            .test(
                'required',
                t('requiredQuestion'),
                (value, context) =>
                    !rentSubsidy ||
                    (context.from && context.from[1] && !context.from[1]?.value.isPrimaryResident) ||
                    value !== null,
            ),
        rentSubsidy: string().when('hasRentSubsidy', {
            is: true,
            then: (schema) =>
                schema.test(
                    'correctSubsidyValue',
                    t('incorrectRentSubsidy'),
                    (value) => Number(value) >= 50 && Number(value) <= 350,
                ),
            otherwise: (schema) => schema.strip(),
        }),
        useRentSubsidyForMaxRent: boolean()
            .nullable()
            .when('hasRentSubsidy', {
                is: true,
                then: (schema) => schema.test('required', t('requiredQuestion'), (value) => value !== null),
            }),
    });
};

const financialDataResidentValidator = (t: TFunction) => {
    return object().shape({
        id: string().required(),
        isPrimaryResident: boolean().required(),
        createdDate: string().required(),
        firstName: string().required(),
        lastName: string().required(),
        financialData: financialDataValidator(t).required(),
    });
};

export function useFinancialDataValidator() {
    const { t } = useTranslation();

    return useMemo(() => {
        return object().shape({
            residents: array(financialDataResidentValidator(t)).required(),
        });
    }, [t]);
}
