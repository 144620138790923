import { differenceInYears, isAfter, isBefore, isMatch, subDays } from 'date-fns';
import { date, mixed } from 'yup';
import { isDateValid } from '../utils';
import { TFunction } from 'i18next';

export const validatePastDate = (t: TFunction) => {
    return mixed<Date | string>()
        .typeError(t('requiredField', { fieldName: t('dateOfBirth') }))
        .test('hasDateValue', t('requiredField', { fieldName: t('dateOfBirth') }), (value) => !!value)
        .test('isValidDate', t('invalidFormat', { fieldName: t('dateOfBirth') }), (value) => {
            if (value instanceof Date && !isNaN(value.getTime())) return isDateValid(value);
            return isMatch(value as unknown as string, 'yyyy-MM-dd');
        })
        .test('dateInThePast', t('dateInThePast'), (value) => {
            if (!value) return false;
            return isBefore(value, subDays(new Date(), 1));
        })
        .test('isYoungerThan', t('ageGreaterThan', { amount: 150 }), (value) => {
            if (!value) return false;
            return differenceInYears(new Date(), new Date(value)) < 150;
        })
        .required(t('requiredField', { fieldName: t('dateOfBirth') }));
};

export const validateFutureDate = (t: TFunction, conditionField: string, condition: boolean) => {
    return date()
        .typeError(t('requiredQuestion'))
        .transform((value) => (value ? value : null))
        .nullable()
        .when(conditionField, {
            is: condition,
            then: (schema) =>
                schema
                    .test('hasDateValue', t('requiredQuestion'), (value) => !!value)
                    .test('isValidDate', t('requiredQuestion'), (value) => {
                        if (value instanceof Date && !isNaN(value.getTime())) return isDateValid(value);
                        return isMatch(value as unknown as string, 'yyyy-MM-dd');
                    })
                    .test('dateInTheFuture', t('dateInTheFuture'), (value) => {
                        if (!value) return false;
                        return isAfter(value, subDays(new Date(), 1));
                    })
                    .required(t('requiredQuestion')),
            otherwise: (schema) => schema.strip(),
        });
};
