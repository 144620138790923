export default {
    nav: {
        title: 'Betaalbaar huren',
        title_antwerp: 'Budgethuren',
        home: 'Home',
        'my-rental-request': 'Mijn Dossier',
    },

    home: {
        title: 'Welkom',
        intro:
            'Met betaalbaar huren biedt de stad kwaliteitsvolle huurwoningen aan in Antwerpen voor mensen die geen eigen woning hebben, die geen woning vinden op de private huurmarkt of geen recht hebben op een sociale huurwoning.' +
            '\n\nStad Antwerpen heeft voor hen momenteel een 100-tal huurwoningen. In de toekomst breiden we dat aanbod uit. Zo kunnen meer mensen genieten van een kwaliteitsvolle, betaalbare huurwoning in onze stad.' +
            '\n\nDoe hier je aanvraag voor betaalbaar huren.',
        intro_antwerp:
            'Met budgethuren biedt de stad kwaliteitsvolle huurwoningen aan in Antwerpen voor mensen die geen eigen woning hebben, die geen woning vinden op de private huurmarkt of geen recht hebben op een sociale huurwoning.' +
            '\n\nStad Antwerpen heeft voor hen momenteel een 100-tal huurwoningen. In de toekomst breiden we dat aanbod uit. Zo kunnen meer mensen genieten van kwaliteitsvolle betaalbare huurwoningen in onze stad.' +
            '\n\n<strong>Opgelet</strong>: voor deze aanvraag heb je een attest van Vlaanderen nodig. De aanvraag duurt 15 werkdagen. Je vraagt dit attest aan via <0>{{link}}</0>.' +
            '\n\nDoe hier je aanvraag voor budgethuren.',
        intro_antwerp_link: 'het Formulierenloket van Wonen in Vlaanderen',
        intro_leuven:
            'Welkom! Met onze budgethuurwoningen biedt AG Stadsontwikkeling Leuven woningen aan waarvan de prijs gemiddeld lager ligt dan de marktprijs. Ze zijn bedoeld voor gezinnen die niet in aanmerking komen voor een sociale huurwoning of op de wachtlijst staan en onvoldoende middelen hebben om op de private markt te huren. De kandidaat-huurders worden gerangschikt aan de hand van een puntensysteem op basis van enkele criteria zoals band met Leuven als woon-, werk- of kennisstad.' +
            '\n\n AG Stadsontwikkeling Leuven zal de volgende jaren haar aanbod van budgethuurwoningen verder uitbreiden. Zo kunnen meer mensen genieten van een kwaliteitsvolle, betaalbare huurwoning in de stad Leuven. Doe hier je aanvraag voor betaalbaar huren.',
        others: 'Andere',
    },

    processDescription: {
        start: 'Schrijf je hier in',
        draft: {
            title: 'Inschrijving',
            description: 'Schrijf je in met de juiste persoons- en inkomensgegevens. Wees volledig!',
            description_leuven: 'Schrijf je in met de juiste persoons- en inkomensgegevens. Wees zo volledig mogelijk!',
        },
        registered: {
            title: 'Controle en aanbod',
            description:
                'We controleren of je gegevens correct zijn. We geven voorrang aan éénoudergezinnen en starters (jonger dan 36 jaar).' +
                '\nWe zoeken een geschikte huurder voor elke huurwoning. Ben jij dat? Dan krijg je een uitnodiging om langs te gaan.',
            description_leuven:
                'We controleren of je gegevens correct zijn. We zoeken een geschikte huurder voor elke huurwoning. Ben jij dat? Dan krijg je een uitnodiging om de huurwoning te bezichtigen.',
        },
        matched: {
            title: 'Plaatsbezoek en toewijzing',
            description: 'Je bezoekt de woning. Was je bezoek succesvol? Dan mag je de woning huren.',
            description_leuven:
                'Je bezoekt de woning. Heb je interesse in de aangeboden woonst en ben je eerste in de lijst ? Dan mag je ze van ons huren.',
        },
    },
};
