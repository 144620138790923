import { boolean, object, string } from 'yup';
import { TFunction } from 'i18next';
import { validateFutureDate } from './date.validator';

export const expectingChildrenValidator = (t: TFunction) => {
    return object({
        isExpecting: boolean().optional(),
        expectedAmount: string().when('isExpecting', {
            is: true,
            then: (schema) =>
                schema
                    .test('required', t('requiredQuestion'), (value) => value !== '' && value !== null)
                    .test('moneyFieldGreaterThanZero', t('moneyFieldGreaterThanZero'), (value) => Number(value) > 0)
                    .test('amountSmallerThan', t('amountSmallerThan', { amount: 4 }), (value) => Number(value) < 4),
            otherwise: (schema) => schema.strip(),
        }),
        expectedDate: validateFutureDate(t, 'isExpecting', true),
    });
};
